.send .textCol {
  min-height: 400px;
  margin-bottom: 100px;
}

@media (max-width: 850px) {
  .send {
    margin-bottom: 50px;
  }
  .send .textCol {
    margin-bottom: 50px;
  }
  .send .modelo {
    bottom: -15%;
  }
}
