.containerPop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999;
}

.overlayPop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff8;
  backdrop-filter: blur(10px);
}

.activePop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popUp {
  padding: 40px 30px;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  width: 500px;
}

/* Steps */

.stepView {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 50%;
  align-self: center;
  justify-content: space-around;
}

.stepView div {
  display: flex;
  flex-direction: column;
  position: relative;
}
.step span {
  width: 12px;
  height: 12px;
  background: #d6d6d6;
  align-self: center;
  margin-bottom: 10px;
  border-radius: 50px;
  position: relative;
  z-index: 1;
}

.step.active span {
  background-color: #3c6cbd;
  box-shadow: 0 0 0 5px #3c6bbd81;
}

.step:not(:first-child):before {
  content: "";
  position: absolute;
  width: 160%;
  height: 2px;
  left: -40px;
  top: 5px;
  z-index: 0;
  background-color: #d6d6d6;
}
.step.active::before {
  background-color: #3c6cbd;
}
.step p {
  font-size: 13px;
}
.step.active p {
  color: #3c6cbd;
}

/* Close */

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  background-color: #aaa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close span {
  width: 60%;
  height: 3px;
  background-color: #fff;
  position: absolute;
  border-radius: 70px;
}
.close span:first-child {
  transform: rotate(45deg);
}
.close span:last-child {
  transform: rotate(-45deg);
}

/* Texts */

.popUp h2 {
  margin: 0 0 10px;
  font-size: 30px;
}

.popUp p {
  margin: 0 0 20px;
}

.step2 .labelForm {
  text-align: center;
}

.step2 .radioDiv {
  align-self: center;
  margin-bottom: 20px;
}

/* Form */

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
}

/* Button */

.navStep {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.buttonForm {
  background: #001e50;
  color: #fff;
  font-size: 16px;
  padding: 14px 25px;
  outline: none;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.btnnext {
  background-color: #001d501f;
  color: #000;
  box-shadow: inset 0px 0px 0px 2px #001e50;
}
.loadBoton {
  opacity: 0.6;
  pointer-events: none;
  cursor: progress !important;
}

.formSuccess {
  background: #6aba6d;
  color: #0007;
  opacity: 0.7;
  pointer-events: none;
}
/* Media */

@media (max-width: 850px) {
  /* Media PopUp */
  .popUp {
    min-width: 200px;
    width: 95%;
    max-width: 400px;
  }

  /* Media Step */

  .stepView {
    width: 90%;
  }
  .step:not(:first-child):before {
    width: 170%;
    left: -53px;
  }

  /* Media Texts */

  .step p {
    font-size: 12px;
  }
  .popUp h2 {
    font-size: 26px;
  }
  .popUp p {
    font-size: 14px;
  }
}
