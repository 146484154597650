/* Footer */
.footer {
  padding-bottom: 20px;
}
.footer p,
.footer a {
  color: #001e50;
  display: flex;
}
.footer a {
  text-decoration: underline;
  margin-left: 5px;
}
.footer p img {
  margin-right: 10px;
}
.textFooter {
  padding: 0 60px;
  height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.textFooter h1 {
  color: #fff;
  font-size: 50px;
}
.imgBck {
  background-image: url(../assets/footerimg.webp);
  width: 55%;
  background-position-x: left;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 500px;
  margin: 0px 0px 0px 0px;
  background-size: cover;
}
.colImg {
  background-image: radial-gradient(
    circle at 0% 0%,
    rgb(0, 67, 122) 0%,
    rgb(0, 30, 80) 100%
  );
  align-items: center;
}
/* Media */

@media (max-width: 850px) {
  .colImg > div {
    width: 100%;
    height: 300px;
  }
  .textFooter {
    height: auto !important;
    justify-content: start;
    padding: 40px 10px;
  }
  .footer > div {
    justify-content: center;
  }
  .footer p {
    margin-bottom: 10px;
    margin-top: 0;
  }
}

/*  /Footer */
