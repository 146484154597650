.fullH {
  margin-top: 140px;
  margin-bottom: 0px;
}
.fullH h1 {
  color: #fafafa;
}

.fullH h4 {
  color: #ffffff8c;
  font-weight: 500;
  margin-bottom: 0;
}
.fullH .h1 span {
  color: #fff;
  font-weight: 700;
}
.modelo {
  margin-left: 90px;
}
.fullH h3 {
  color: #fff;
  font-weight: 400;
}

.stroke {
  -webkit-text-stroke: 1px #fafafa;
  color: transparent;
  font-weight: 700;
}

.textCol {
  width: 60%;
  min-width: 500px;
  position: relative;
}
.modelo {
  width: 40%;
}

.colorBox {
  height: 100%;
  width: 100%;
  z-index: 0;
  left: 0;
  bottom: 0;
  padding: 40px 60px 60px;
  border-radius: 5px;
  position: relative;
}

.fullw {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  height: 100px !important;
}
.modelo {
  pointer-events: none;
}

.fullH img {
  width: 100%;
  max-width: 500px;
  transform: scale(1.8);
  transform-origin: left;
  position: relative;
  top: 30%;
  left: -20%;
}

.textCol::before {
  content: "";
  position: absolute;
  top: -10%;
  right: -20%;
  background-image: radial-gradient(
    circle at 0% 0%,
    rgb(0, 67, 122) 0%,
    rgb(0, 30, 80) 100%
  );
  width: 200%;
  height: 123%;
}

/* Media */

@media (max-width: 1200px) {
  .colorBox {
    padding: 0;
    flex-direction: column;
    margin-bottom: 130px;
  }
  .textCol {
    width: 100%;
    min-width: auto;
  }
  .modelo {
    width: 100%;
  }
  .fullH img {
    transform: scale(1.5);
    max-width: 450px;
    top: auto;
    left: auto;
  }
  .modelo {
    position: absolute;
    bottom: -30%;
    width: 100vw;
    right: -80px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 850px) {
  .fullH {
    margin-top: 120px;
    margin-bottom: 60px;
  }
  .fullH img {
    max-width: 330px;
  }
  .modelo {
    bottom: -55%;
  }
}
